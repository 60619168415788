<template>
    <!-- <div class="swipper" @mousedown="onMouseDown" @mouseup="onMouseUp" @mousemove="onMouseMove" > -->
    <div class="swipper" @mousewheel="onMouseWheel" @mousemove="onMouseMove">
        <div class="content" :style="{ 'left': contentLeft + 'px', 'transition-property': animLeft, 'transition-duration': animDuration }">
            <div class="other1 content-page" :style="{ width: pageW + 'px', height: pageH + 'px', 'margin-right': pagePadding + 'px' }">
                <div class="page-other">
                    <!-- <img src="../assets/other_1.png" alt="" /> -->
                </div>
            </div>

            <div class="home content-page bg-grey" :style="{ width: pageW + 'px', height: (pageW / 1.2) + 'px', 'margin-right': pagePadding + 'px' }">
                <img class="index" v-lazy="lstImg[0]" alt="" />
                <img class="eyelid" :class="{blink: eyeblink}" src="../assets/eyelid.png"/>
                <img class="eyeball" :style="{left: eyeLeft + 'px', top: eyeTop + 'px', width: eyeWidth + 'px', height: eyeHeight + 'px'}" src="../assets/eyeball.png" alt=""/>
            </div>

            <div class="about content-page" :style="{ width: pageW + 'px', height: pageH + 'px', 'margin-right': pagePadding + 'px' }">
                <div class="page-about">
                    <div class="page-about-img">
                      <swiper :options="swiperOption">
                        <swiper-slide class="swiper-slide" v-for="(item,index) in carouselArr" :key="index">
                          <img class="swiper-img" v-lazy="item" alt="" />
                        </swiper-slide>
                        <!-- 分页器 -->
                        <div class="swiper-pagination"  slot="pagination" v-if="carouselArr.length > 1"></div>   
                      </swiper>
                    </div>
                    <div class="page-about-detail">
                        <div v-if="$store.state.isCN">
                          奥古布古网络科技有限公司（Ogopogo），2021年在深圳成立的游戏公司。
                          <br><br>
                          核心成员均来自腾讯自研工作室，团队成员具备丰富的游戏开发、企业管理经验，曾创造过多款口碑与流水双赢的知名项目，代表作有《火影忍者》手游、《一人之下》手游、《王牌战士》、《弹道轨迹》、《全民水浒》、《Q宠大乐斗》等。
                          <br><br>
                          我们认为游戏的艺术性与商业性并非对立，在两者之间取得平衡，对我们来说更具挑战。
                          <br><br>
                          做出印象深刻，具有魅力的游戏是我们的目标，而在作品中给玩家以及自己都带来感动是我们持续不懈的追求。
                        </div>
                        <div v-else>
                          Ogopogo Network Technology Co., Ltd. (Ogopogo), a game company established in Shenzhen in 2021.
                          <br><br>
                          The core members are all from Tencent's self-developed studio. The team members have rich experience in game development and business management. They have created many well-known projects that are both word-of-mouth and win-win. Representative works include "Naruto" mobile game and "Under One Man" mobile game.You, "Ace Warrior", "Ballistic Trajectory", "Water Margin", "Q Chong Da Le Dou", etc.
                          <br><br>
                          We believe that the artistry and commerciality of games are not opposites. It is more challenging for us to strike a balance between the two.
                          <br><br>
                          It is our goal to make impressive and attractive games, and it is our unremitting pursuit to move players and ourselves in the works.
                        </div>
                    </div>
                </div>
            </div>

            <div class="job content-page" :style="{ width: pageW + 'px', height: pageH + 'px', 'margin-right': pagePadding + 'px' }" @mouseenter="onNoWheelEnter" @mouseleave="onOnWheelLeave">
                <JobPage></JobPage>
            </div>

            <div class="about content-page" :style="{ width: pageW + 'px', height: pageH + 'px', 'margin-right': pagePadding + 'px' }" @mouseenter="onNoWheelEnter" @mouseleave="onOnWheelLeave">
                <div class="page-map">
                    <div class="page-about-map">
                      <!-- <img src="../assets/about_2.png" alt="" /> -->
                      <el-amap vid="amapDemo" :center="center" :zoom="zoom">
                        <el-amap-info-window :position="currentWindow.position" :content="currentWindow.content">
                        </el-amap-info-window>
                        <el-amap-circle :center="circle.center" :radius="circle.radius" :stroke-weight="circle.strokeWeight" :fill-color="circle.fillColor" :fill-opacity="circle.fillOpacity" :stroke-color="circle.strokeColor">
                        </el-amap-circle>
                        <el-amap-circle-marker :center="circlePoint.center" :radius="circlePoint.radius" :fill-color="circlePoint.fillColor" :fill-opacity="circlePoint.fillOpacity" :stroke-color="circlePoint.strokeColor">
                        </el-amap-circle-marker>
                      </el-amap>
                    </div>

                    <div class="page-about-detail">
                      <div class="address">
                        <div v-if="$store.state.isCN">
                          <p>深圳市前海港澳青年梦工厂10栋506</p>
                        </div>
                        <div v-else>
                          Room 506, Building 10, Youth Dream Factory, Qianhai Hong Kong and Macao, Shenzhen
                        </div>
                        
                        <div class="copyright">© 2021 ALL RIGHTS RESERVED<br/>WWW.OGOPOGO.CC</div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="other1 content-page bg-blue" :style="{ width: pageW + 'px', height: (pageW / 1.2) + 'px', 'margin-right': pagePadding + 'px' }">
                <div class="page-other">
                    <!-- <img src="../assets/other_1.png" alt="" /> -->
                </div>
            </div>

        </div>

        <div class="arrow-left-btn" @click="onLeftClick">
        </div>

        <div class="arrow-right-btn" @click="onRightClick">
        </div>
    </div>
</template>

<script>
import JobPage from './Job.vue';

export default {
  name: 'Swipper',
  props: ['left', 'pageW', 'pageH', 'pagePadding'],

  data() {
    return {
      /// swaipper 参数
      isDragging: false,
      startPosX: 0,
      contentStartPosX: 0,
      contentLeft: 0,
      animLeft: 'left',
      animDuration: '0.6s',

      minIdx: 1,
      maxIdx: 4,
      /// 滚轮
      lstScroll: [],
      canWheel: true,
      ///
      lstImg: [
        require('../assets/big_eye.png'),//首页图
      ],
      /// 分页器
      swiperOption:{
        //显示分页
        pagination: {
          el: '.swiper-pagination'
        },
        //自动轮播
        autoplay: {
          delay: 2000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        //开启循环模式
        loop: false
      },
      carouselArr: [
        require('../assets/us_1.jpg'),
        require('../assets/us_2.jpg'),
        require('../assets/us_3.jpg'),
        require('../assets/us_4.jpg')
      ],
      /// 眼球
      eyeblink: false,
      eyeLeft: 0,
      eyeTop: 0,
      eyeWidth: 0,
      eyeHeight: 0,
      /// 地图
      zoom: 16,
      center: [113.90082,22.518799],
      currentWindow: {
        position: [113.90082,22.518799],
        content: '我在青年梦工厂附近>'
      },
      circlePoint: {
        center: [113.90082,22.518799],
        radius: 5,
        fillOpacity: 1,
        strokeColor: '#ffffff',
        fillColor: '#013fed'
      },
      circle: {
        center: [113.90082,22.518799],
        radius: 5,
        strokeWeight: 1,
        fillOpacity: 0.5,
        strokeColor: '#013fed',
        fillColor: '#ffffff'
      }



    }
  },
  components: {
    JobPage
  },
  methods: {
    onMouseDown(evt) {
      this.isDragging = true
      this.startPosX = evt.pageX
      this.contentStartPosX = this.contentLeft
      this.animLeft = ''
      this.animDuration = ''
    },
    onMouseUp(evt) {
      this.handleMouseUp(evt)
    },
    onMouseOut(evt) {
      this.handleMouseUp(evt)
    },
    onMouseMove(evt) {
      if (!this.isPC()) return

      var halfW = document.documentElement.clientWidth / 2.0
      var halfH = document.documentElement.clientHeight / 2.0
      var halfPageW = this.pageW / 2.0
      var halfPageH = this.pageH / 2.0
      var x = evt.clientX - halfW
      var y = evt.clientY - halfH


      var len = Math.sqrt(x * x + y * y)
      // console.log(x, y, len)
      // 眼球活动半径
      var r = this.vh2pixel(11.2)
      if (len < r) {
        r = len
      }
      var eyeX = r * x / len
      var eyeY = r * y / len
      // console.log('halfPage', halfPageW, halfPageH, eyeX, eyeY)

      this.eyeLeft = halfPageW + eyeX - this.eyeWidth / 2.0
      this.eyeTop = halfPageH + eyeY - this.eyeHeight / 2.0
    },
    onMouseWheel(evt) {
      var delta = evt.wheelDelta || evt.detail
      this.handleWheel(delta)
    },
    onNoWheelEnter() {
      this.canWheel = false
    },
    onOnWheelLeave() {
      this.canWheel = true
    },
    onLeftClick() {
      var cnt = this.$store.state.curPage
      cnt--
      if (cnt < this.minIdx) {
        cnt = this.maxIdx
      }
      this.jumpTo(cnt)
    },
    onRightClick() {
      var cnt = this.$store.state.curPage
      cnt++
      if (cnt > this.maxIdx) {
        cnt = this.minIdx
      }
      this.jumpTo(cnt)
    },
    vh2pixel(vh) {
      return document.documentElement.clientHeight * vh * 0.01
    },
    vw2pixel(vw) {
      return document.documentElement.clientWidth * vw * 0.01
    },
    jumpTo(page) {
      this.$store.commit('setCnt', page)

      var fill = (document.documentElement.clientWidth - this.pageW) / 2
      var left = fill - page * (this.pageW + this.pagePadding)

      console.log('[jumpTo] left', left)
      this.contentLeft = left
    },
    handleMouseUp(evt) {
      this.animLeft = 'left'
      this.animDuration = '0.6s'
      this.isDragging = false
      var fill = (this.vw2pixel(100) - this.vh2pixel(this.pageVH)) / 2

      var pageFloat = (fill - this.contentLeft) / (this.vh2pixel(this.pageVH) + this.vw2pixel(this.pagePaddingVW))
      if (evt.pageX > this.startPosX) {
        this.jumpTo(Math.floor(pageFloat))
      } else {
        this.jumpTo(Math.ceil(pageFloat))
      }
    },
    handleWheel(delta) {
      // 不支持滚动的页
      // console.log('[handleWheel] canWheel:', this.canWheel)
      if (!this.canWheel) return

      this.lstScroll.push(delta)

      var len = this.lstScroll.length
      if (len < 3) return

      if (delta < 0) {
        // 下一页
        this.onRightClick()
      } else {
        // 上一页
        this.onLeftClick()
      }

      this.lstScroll = []
    },
    isPC() {
      var userAgentInfo = navigator.userAgent;
       var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
       var flag = true;
       for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
             }
       }
       return flag
    }
  },
  watch: {
    left(newV) {
      this.contentLeft = newV
      console.log(newV)
    }
  },
  mounted() {
    this.pageVH = 95.36
    this.pageWidthVH = 80

    setTimeout(() => {
      this.eyeHeight = this.pageW / 1.2 * 0.492
      this.eyeWidth = this.eyeHeight * 0.187
      this.eyeLeft = (this.pageW - this.eyeWidth) / 2.0
      this.eyeTop = (this.pageW / 1.2 - this.eyeHeight) / 2.0
    })

    setTimeout(() => {
      this.eyeblink = true
    }, 500)
  }
}
</script>

<style scoped>
.eyelid {
    position: absolute;
    top: -51%;
    width: 51%;
    margin-left: -25.3%;
    left: 50%;

    transition-property: top;
    transition-duration: 0.5s;
}

.eyeball {
  position: absolute;
  top: 50%;
  left: 50%;
}

.home.content-page {
  padding: 0;
  overflow: hidden;
}

.eyelid.blink {
    animation: 0.8s eyeblink;
}

@keyframes eyeblink {
  0% {
    top: -51%;
  }
  50% {
    top: -19%;
  }
  100% {
    top: -51%;
  }
}

.swipper {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.content {
    position: absolute;
    display: inline-flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    width: auto;
    height: 100%;
}

.arrow-left-btn {
    position: absolute;
    margin-top: 80px;
    margin-bottom: 80px;

    width: 20%;
    height: 100%;
    left: 0;
}

.arrow-right-btn {
    position: absolute;
    margin-top: 80px;
    margin-bottom: 80px;

    width: 20%;
    height: 100%;
    right: 0;
}

/* ----------------------- home ----------------------- */
.index {
    width: auto;
    height: 100%;
    pointer-events: none;
}

/* ----------------------- content ----------------------- */
.content-page {
    position: relative;
    /* border-radius: 21vh; */
    background-color: white;

    color: #013fed;
    margin-left: 0;
}

.page a {
    text-decoration: none;
    color: #013fed;
    font-weight: bold;
}

/* ----------------------- about ----------------------- */
.page-about {
    /* display: flex;
    flex-direction: row;
    
    margin: 0 auto;
    width: 75%;
    height: 60%;
    margin-top: 15%; */
}

.page-map {
    /* display: flex;
    flex-direction: col;
    
    margin: 0 auto;
    width: 75%;
    height: 60%;
    margin-top: 15%; */
}

.page-about-img {
    /* width: 50%; */
}

.page-about-map {
    width: 100%;
}

.page-map > .page-about-detail {
    margin-top: 5%;
}

.page-about-img > img {
    width: 100%;
    padding-bottom: 10px;
    pointer-events: none;
}

.page-about-img .swiper-img {
    width: 100%;
    padding-bottom: 10px;
    pointer-events: none;
    border-radius: 2vh;
}

.page-about-img .swiper-container {
    height: 100%;
}

.page-about > .page-about-detail {
    position: relative;
    text-align: left;
    line-height: 25px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;

    /* margin-left: 30px; */
    width: 100%;
}
.page-about > .page-about-detail:hover {
    overflow-y: visible;
}

.page-about-detail .address {
    text-align: center;
    /* top: 35%; */

    width: 100%;
}

.page-about-detail .address p {
    /* letter-spacing: 2px; */
}

.page-about-detail .copyright {
    font-family: retro;
}

/* ----------------------- other ----------------------- */
.page-other {
    position: relative;
    overflow: hidden;
}

.page-other > img {
    width: 59.55%;
    pointer-events: none;
}


/* ----------------- common --------------------- */
.cn {
    font-family: syht;
}

.en {
    font-family: retro;
}

.bg-blue {
  background-color: #013fed;
}

.bg-grey {
  background-color: #CCCCCC;
}

</style>
