<template>
  <div id="app" :class="[isGlobalCn? 'lang-cn' : 'lang-en']">
    <div class="nav">
      <img class="logo btn" src="./assets/logo.png" @click="onLogoClick" />
      <img class="nav-menu btn" src="./assets/nav_menu.png" @click="onMenuClick" />
    </div>

    <Swipper ref='swipper' :left="menuLeft" :pageW="pageWidth" :pageH="pageHeight" :pagePadding="pagePadding"></Swipper>
    <Footer></Footer>
    <LangBtn></LangBtn>

    <NavMenu ref='menu' v-on:navigateTo="onNavigateTo"></NavMenu>
  </div>
</template>

<script>
import LangBtn from './components/LangBtn.vue';
import NavMenu from './components/NavMenu.vue';
import Swipper from './components/Swipper.vue';
import Footer from './components/Footer.vue';

export default {
  data() {
    return {
      isGlobalCn: true,
      showClass: '',
      menuLeft: 0,

      designW: 900,// 设计分辨率
      designH: 750,
      pageWidth: 0,// 实际page分辨率
      pageHeight: 0,

      pagePadding: 0,
    }
  },
  watch: {
      '$store.state.isCN': function(newVal) {
          if (newVal) {
              this.isGlobalCn = true
          } else {
              this.isGlobalCn = false
          }
      }
  },
  methods: {
    onLogoClick() {
      console.log('[onLogoClick]')
      this.$refs.swipper.jumpTo(1)
    },
    onMenuClick() {
      console.log('[onMenuClick]')
      this.$refs.menu.showMenu()
    },
    vh2pixel(vh) {
      return document.documentElement.clientHeight * vh * 0.01
    },
    vw2pixel(vw) {
      return document.documentElement.clientWidth * vw * 0.01
    },
    onNavigateTo(idx) {
      console.log('[App.onNavigateTo]', idx)
      this.$refs.swipper.jumpTo(idx)
    },
    calPageW2Pixel(percent) {
      var isRotated = document.documentElement.clientWidth > document.documentElement.clientHeight
      var delta = this.designW / this.designH
      var w = delta * percent

      var result, scale
      if (isRotated) {
        // 横屏
        result = this.vh2pixel(w)
        scale = 1
      } else {
        // 竖屏
        result = this.vw2pixel(w)
        // 竖屏时，不同分辨率下，page的缩放系数
        scale = this.scaleFilter(document.documentElement.clientWidth)
      }

      this.pageWidth = result
      this.pageHeight = result / delta * scale

      // 计算页面间间距
      if (scale == 1) {
        this.pagePadding = (document.documentElement.clientWidth - this.pageWidth) / 2.0 - this.vw2pixel(5.5)
      } else {
        this.pagePadding = 0
      }

      console.log('[calPageW2Pixel]', this.pageWidth, this.pageHeight)
      console.log('[calPageW2Pixel]:', scale, isRotated)
      console.log('[calPageW2Pixel] pagePadding:', this.pagePadding)
    },
    scaleFilter(clientW) {
      var result
      if (clientW < 540) {
        // 小屏
        result = 2
      } else if (clientW >= 540 & clientW < 1200) {
        // 中屏
        result = 1.2
      } else {
        // 大屏
        result = 1
      }
      return result
    }
  },
  components: {
    LangBtn, NavMenu, Swipper, Footer
  },
  mounted() {
    this.pageVH = 95.36

    console.log('[App.mounted]')

    this.calPageW2Pixel(84)
    setTimeout(() => {
      this.$refs.swipper.jumpTo(1)
    })
  }
}
</script>

<style>
@font-face {
  font-family: syht;
  src: url('./assets/syht_cn_regular.otf');
}

@font-face {
  font-family: helveti;
  src: url('./assets/helveticaneue-light.otf');
}

@font-face {
  font-family: retro;
  src: url('./assets/retro_computer_personal_use.ttf');
}

@font-face {
  font-family: mplus;
  src: url('./assets/mplus_hzk_13.ttf');
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;

  /* font-family: syht, helveti, retro, mplus, Helvetica, Tahoma, Arial, STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑", SimSun, "宋体", Heiti, "黑体", sans-serif; */
}

html, body {
    position: relative;
    background-color: #CCCCCC;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;
    box-sizing: border-box;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* padding: 27px 50px 0px 50px; */
}

.logo {
  /* width: 196px;
  height: 30px; */

  z-index: 5;
}

.nav-menu {
  /* width: 26px;
  height: 25px; */

  z-index: 5;
}

.btn {
  cursor: pointer;
}

.lang-btn {
  position: absolute;
  /* right: 50px;
  bottom: 27px; */
}

.lang-cn {
  font-family: syht;
}

.lang-en {
  font-family: helveti;
}

.lang-en-retro {
  font-family: retro;
}

/* ----------------- 分页器 --------------------- */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 90%;
}

.page-about .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar {  
    width: 4px;
    height: 16px;
    background-color: #013FED;
}  
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(1, 63, 237, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}  
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb {  
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #013FED;
}  
</style>