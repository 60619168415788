import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLazyload from 'vue-lazyload'
import VueAmap from 'vue-amap'

import './styles/swiper.css'
import './styles/media.css'


Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'dist/loading.gif',
  attempt: 1
})

Vue.use(VueAmap)
VueAmap.initAMapApiLoader({
  key: 'fae91ca041eb8b1f502cb96088e153a6',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.Geolocation'],
  v: '1.4.4'
});

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
