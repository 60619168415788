<template>
    <div class="footer">
        <a href="https://beian.miit.gov.cn/">© 2021 OGOPOGO 粤ICP备2021086955号</a>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {}
    },
    methods: {

    }
}
</script>

<style scoped>
.footer {
    position: fixed;
    width:100%;
    height: auto;
    text-align: left;

    /* left: 50px; */
    bottom: 27px;
}

.footer > a {
    text-decoration: none;
    color: white;
    font-size: 0.8rem;
}
</style>