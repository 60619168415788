import Vue from "vue"
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        curPage: 0,
        isCN: true,
    },
    mutations: {
        setCnt(state, n) {
            state.curPage = n
        },
        setIsCN(state, bl) {
            state.isCN = bl
        }
    }
})