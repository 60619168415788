<template>
    <div class="lang-btn">
        <div class="lang-en-btn btn" :class="{ active: isCN === false }" @click="switchToLang('en-US')">EN</div>
        <div class="lang-cn-btn btn" :class="{ active: isCN === true }" @click="switchToLang('zh-CN')">CN</div>
    </div>
</template>

<script>
export default {
  name: 'LangBtn',
  data() {
    return {
        'isCN': true,
    }
  },
  methods: {
      switchToLang(lang) {
          var bl = lang === 'zh-CN'
          this.$store.commit('setIsCN', bl)
          this.isCN = bl
      }
  }
}
</script>

<style scoped>
.lang-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    background-color: white;
    /* width: 75px;
    height: 25px; */
    border-radius: 13px;
}

.btn {
    text-align: center;
    /* width: 40px;
    height: 25px;
    line-height: 25px; */
    z-index: 2;
    font-size: 0.9rem;
    color: #013fed;

    font-family: retro;
}

.btn.active {
    color: white;
    /* width: 40px;
    height: 25px; */
    border-radius: 13px;
    background-color: #013fed;

    transition-property: left;
    transition-duration: 0.6s;
    z-index: 1;
}
</style>
