<template>
    <div class="page-job">
        <div class="group-btn">
            <div v-if="$store.state.isCN">
                <div class="btn" :class="{'active':idx === curBtnIdx}" v-for="(btn, idx) in BTNS_CN" :key="idx" @click="onBtnClick(idx)">{{btn}}</div>
            </div>
            <div v-else>
                <div class="btn" :class="{'active':idx === curBtnIdx}" v-for="(btn, idx) in BTNS_EN" :key="idx" @click="onBtnClick(idx)">{{btn}}</div>
            </div>
        </div>
        
        <div class="group-detail">
            <div class="detail" v-for="(item, i) in lstShow" :key="i">
                <div class="title">{{item.name}}</div>
                <div class="job-detail">
                    <ul>
                        <li v-for="(msg, j) in item.msg" :key="j">
                            <p v-html="msg"></p>
                        </li>
                    </ul>
                </div>
                

                <div class="group-apply-btn">
                    <div class="apply-btn" @click="OnApplyClick(i)">
                        <div v-if="$store.state.isCN">
                            立即申请
                        </div>
                        <div v-else>
                            Send Mail
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Job',
    data() {
        return {
            BTNS_CN: [
                "美术类", "技术类"
            ],
            BTNS_EN: [
                "Art", "Technology"
            ],

            DETAIL_CN:[
                // 美术
                [{
                    name: '资深游戏UI设计师',
                    msg: [
                        '<b>工作职责：</b>',
                        '配合产品美术负责人制定游戏UI风格，输出UI设计模版和规范；',
                        '参与具体UI制作任务，审核外包提交的UI资源，指导外包制作；',
                        '完成与动画师策划，特效师程序的良好沟通与高度衔接，与动画，程序，特效完成配合合入UI效果与功能；',
                        '<br/><b>任职要求：</b>',
                        '有创意，富于想象力和创造力，思维活跃，热衷游戏事业',
                        '美术相关专业毕业，大专以上学历，有较强的设计感，色彩感觉和美术手绘功底，懂得人机交互设计，有主导团队开展设计工作经验者尤佳；',
                        '精通UI设计所需要的常规软件；',
                        '有较强的综合分析能力，创新能力，准确理解工作需求并高效完成；',
                        '熟悉游戏UI开发流程并具有良好的团队合作意识，能很好的与他人协同完成工作；',
                        '具备良好的团队合作精神，较强的责任心和沟通能力，积极主动，并能承受较强的工作压力。',
                    ]
                },{
                    name: '2D游戏场景设计',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责场景的设计、和物件拆分；',
                        '负责宣传图气氛、构图的设计；',
                        '负责优化宣传素材；',
                        '负责外包资源的审核修改，对外包资源进行审核修改，统一风格，并提高外包的制作品质。',
                        '<br/><b>任职要求：</b>',
                        '大专以上学历，3年以上游戏相关工作经验；',
                        '态度严谨，工作认真负责，有团队精神，沟通能力配合工作能力强，对游戏角色设计有热情；',
                        '美术功底扎实，具备良好的绘画技巧，对结构、线条、色彩的把控较强；',
                        '视野广阔，有一定高度的审美水平；',
                        '设计风格较灵活，擅长多种风格的场景设计；',
                        '有与时俱进的设计思维，丰富的想象力和创新能力，对自己的作品要求高；',
                        '具备良好的沟通能力，善于思考，思维灵活，富有创意，乐于助人；',
                        '热爱游戏，关注游戏体验，结合本岗位工作配合产品改进游戏体验；',
                        '应聘简历务必附作品。',
                    ]
                },{
                    name: '游戏UI设计师',
                    msg: [
                        '<b>工作职责：</b>',
                        '配合产品美术负责人制定游戏UI风格，输出UI设计模版和规范；',
                        '参与具体UI制作任务，审核外包提交的UI资源，指导外包制作；',
                        '完成与动画师策划，特效师程序的良好沟通与高度衔接，与动画，程序，特效完成配合合入UI效果与功能；',
                        '<br/><b>任职要求：</b>',
                        '有创意，富于想象力和创造力，思维活跃，热衷游戏事业',
                        '美术相关专业毕业，大专以上学历，有较强的设计感，色彩感觉和美术手绘功底，懂得人机交互设计，有主导团队开展设计工作经验者尤佳；',
                        '精通UI设计所需要的常规软件；',
                        '有较强的综合分析能力，创新能力，准确理解工作需求并高效完成；',
                        '熟悉游戏UI开发流程并具有良好的团队合作意识，能很好的与他人协同完成工作；',
                        '具备良好的团队合作精神，较强的责任心和沟通能力，积极主动，并能承受较强的工作压力。',
                    ]
                },{
                    name: '2D手游场景原画',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责游戏中的横板场景美术设计，场景美术风格制定，优化场景细节；',
                        '负责与美术其他岗位、程序配合，完善游戏最终表现效果；',
                        '负责对接管理CP，提供明确需求到对应CP并且把控好输出品质。',
                        '<br/><b>任职要求：</b>',
                        '大专及以上学历，游戏场景设计相关的工作经验，有CP管理经验者优先；',
                        '熟悉各类美术风格的场景设计，擅长欧美卡通美术风格优先；',
                        '需要有场景整体氛围把控的能力,造型能力强、色感优越，扎实的美术基础，有较强的风格适应能力和学习能力，能够不断提高游戏美术的表现力，熟悉主流游戏美术风格,对游戏行业有热情；',
                        '具有良好的沟通能力与团队合作精神,积极主动解决问题，责任心强。',
                    ]
                },{
                    name: '手游UI设计师',
                    msg: [
                        '<b>工作职责：</b>',
                        '配合产品美术负责人制定游戏UI风格，输出UI设计模版和规范；',
                        '参与具体UI制作任务，审核外包提交的UI资源，指导外包制作；',
                        '完成与动画师策划，特效师程序的良好沟通与高度衔接，与动画，程序，特效完成配合合入UI效果与功能；',
                        '积累和共享UI设计经验。',
                        '<br/><b>任职要求：</b>',
                        '美术相关专业毕业，大专以上学历，有较强的设计感，色彩感觉和美术手绘功底，懂得人机交互设计，有主导团队开展设计工作经验者尤佳；',
                        '精通UI设计所需要的常规软件；',
                        '有较强的综合分析能力，创新能力，准确理解工作需求并高效完成；',
                        '熟悉游戏UI开发流程并具有良好的团队合作意识，能很好的与他人协同完成工作；',
                        '有创意，富于想象力和创造力，思维活跃，热衷游戏事业；',
                        '具备良好的团队合作精神，较强的责任心和沟通能力，积极主动，并能承受较强的工作压力。',
                    ]
                },{
                    name: '2D游戏场景设计师',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责游戏中的场景美术设计，场景美术风格制定，优化场景细节；',
                        '负责与美术其他岗位、程序配合，完善游戏最终表现效果；',
                        '负责对接管理CP，提供明确需求到对应CP并且把控好输出品质。',
                        '<br/><b>任职要求：</b>',
                        '大专及以上学历，3年以上游戏场景设计相关的工作经验，有CP管理经验者优先；',
                        '熟悉各类美术风格的场景设计，擅长欧美卡通美术风格优先；',
                        '需要有场景整体氛围把控的能力,造型能力强、色感优越，扎实的美术基础，有较强的风格适应能力和学习能力，能够不断提高游戏美术的表现力，熟悉主流游戏美术风格,对游戏行业有热情；',
                        '熟悉游戏UI开发流程并具有良好的团队合作意识，能很好的与他人协同完成工作；',
                        '具有良好的沟通能力与团队合作精神,积极主动解决问题，责任心强。',
                    ]
                },{
                    name: '资深2D游戏场景设计师',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责游戏中的场景美术设计，场景美术风格制定，优化场景细节；',
                        '负责与美术其他岗位、程序配合，完善游戏最终表现效果；',
                        '负责对接管理CP，提供明确需求到对应CP并且把控好输出品质。',
                        '<br/><b>任职要求：</b>',
                        '大专及以上学历，3年以上游戏场景设计相关的工作经验，有CP管理经验者优先；',
                        '熟悉各类美术风格的场景设计，擅长欧美卡通美术风格优先；',
                        '需要有场景整体氛围把控的能力,造型能力强、色感优越，扎实的美术基础，有较强的风格适应能力和学习能力，能够不断提高游戏美术的表现力，熟悉主流游戏美术风格,对游戏行业有热情；',
                        '具有良好的沟通能力与团队合作精神,积极主动解决问题，责任心强。',
                    ]
                }],
                // 技术
                [{
                    name: 'U3D游戏开发工程师',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责Unity手游项目功能模块的设计和开发',
                        '负责对策划案功能进行还原和开发',
                        '负责游戏编辑工具的开发',
                        '负责UGUI制作，组件开发',
                        'shader效果编写',
                        '<br/><b>任职要求：</b>',
                        '本科以上学历，热爱游戏，未来规划在游戏设计深入发展',
                        '熟练掌握C#、lua等编程语言及面向对象编程、具有良好的代码风格',
                        '了解掌握Unity ShaderLab的编写',
                        '熟练掌握Unity3D开发环境',
                        '熟练掌握UGUI，UI合批等底层机制',
                        '对产品品质和体验有追求，能从玩家角度去分析和评判问题，对一个游戏功能可以提出多种实现方式，权衡不同实现',
                        '善与人沟通，有较强的责任心和团队合作精神',
                    ]
                },{
                    name: '高级Unity游戏工程师',
                    msg: [
                        '<b>工作职责：</b>',
                        '负责Unity手游项目功能模块的设计和开发',
                        '负责对策划案功能进行还原和开发',
                        '负责游戏编辑工具的开发',
                        '负责UGUI制作，组件开发',
                        'shader效果编写',
                        '核心单局战斗开发',
                        '核心框架优化迭代',
                        '<br/><b>任职要求：</b>',
                        '本科以上学历，热爱游戏，未来规划在游戏设计深入发展',
                        '熟练掌握C#、lua等编程语言及面向对象编程、具有良好的代码风格',
                        '了解掌握Unity ShaderLab的编写',
                        '熟练掌握Unity3D开发环境',
                        '熟练掌握UGUI，UI合批等底层机制',
                        '对产品品质和体验有追求，能从玩家角度去分析和评判问题，对一个游戏功能可以提出多种实现方式，权衡不同实现',
                        '善与人沟通，有较强的责任心和团队合作精神',
                    ]
                }],
                
            ],

            DETAIL_EN:[
                // 美术
                [{
                    name: 'Senior game UI designer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Cooperate with the person in charge of product art to formulate the game UI style, and output UI design templates and specifications;',
                        'Participate in specific UI production tasks, review UI resources submitted by outsourcing, and guide outsourcing production;',
                        'Complete good communication and high-level connection with the animator and the program of the animator, and integrate the UI effects and functions with the completion of animation, programs, and special effects;',
                        '<br/><b>Job requirements:</b>',
                        'Creative, full of imagination and creativity, active thinking, passionate about gaming',
                        'Graduated from art-related majors, college degree or above, with a strong sense of design, color perception and art hand-painting skills, understand human-computer interaction design, and have experience in leading the team to carry out design work is preferred;',
                        'Proficient in conventional software required for UI design;',
                        'Have strong comprehensive analysis ability, innovation ability, accurate understanding of work needs and efficient completion;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                    ]
                },{
                    name: '2D game scene design',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the design of the scene and the separation of objects;',
                        'Responsible for the design of the atmosphere and composition of the promotional image;',
                        'Responsible for optimizing promotional materials;',
                        'Responsible for the review and modification of outsourcing resources, review and modify outsourcing resources, unify the style, and improve the quality of outsourcing production.',
                        '<br/><b>Job requirements:</b>',
                        'College degree or above, more than 3 years of game-related work experience;',
                        'Strict attitude, serious and responsible work, team spirit, strong communication skills and working ability, and enthusiasm for game character design;',
                        'Solid art skills, good drawing skills, strong control over structure, lines and colors;',
                        'Have a broad field of vision and a certain high level of aesthetics;',
                        'The design style is more flexible, good at various styles of scene design;',
                        'Have design thinking that keeps pace with the times, rich imagination and innovation ability, and have high requirements for their own works;',
                        'Have good communication skills, good at thinking, flexible thinking, creative and helpful;',
                        'Love the game, pay attention to the game experience, and improve the game experience in combination with the work of the position and the product;',
                        'The resume must be accompanied by a work.',
                    ]
                },{
                    name: 'Game UI designer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Cooperate with the person in charge of product art to formulate the game UI style, and output UI design templates and specifications;',
                        'Participate in specific UI production tasks, review UI resources submitted by outsourcing, and guide outsourcing production;',
                        'Complete good communication and high-level connection with the animator and the program of the animator, and integrate the UI effects and functions with the completion of animation, programs, and special effects;',
                        '<br/><b>Job requirements:</b>',
                        'Creative, full of imagination and creativity, active thinking, passionate about gaming',
                        'Graduated from art-related majors, college degree or above, with a strong sense of design, color perception and art hand-painting skills, understand human-computer interaction design, and have experience in leading the team to carry out design work is preferred;',
                        'Proficient in conventional software required for UI design;',
                        'Have strong comprehensive analysis ability, innovation ability, accurate understanding of work needs and efficient completion;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                    ]
                },{
                    name: 'Original 2D mobile game scene',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the art design of the horizontal board scene in the game, the formulation of the scene art style, and the optimization of the scene details;',
                        'Responsible for cooperating with other positions and procedures of art to improve the final performance of the game;',
                        'Responsible for the docking management CP, providing clear requirements to the corresponding CP and controlling the output quality.',
                        '<br/><b>Job requirements:</b>',
                        'College degree or above, work experience related to game scene design, CP management experience is preferred;',
                        'Familiar with the scene design of various art styles, good at European and American cartoon art style is preferred;',
                        'It needs the ability to control the overall atmosphere of the scene, strong modeling ability, superior color perception, solid art foundation, strong style adaptability and learning ability, can continuously improve the expressive power of game art, and be familiar with mainstream game art styles. Enthusiasm in the game industry;',
                        'Have good communication skills and teamwork spirit, proactively solve problems, and have a strong sense of responsibility.',
                    ]
                },{
                    name: 'Mobile game UI designer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Cooperate with the person in charge of product art to formulate the game UI style, and output UI design templates and specifications;',
                        'Participate in specific UI production tasks, review UI resources submitted by outsourcing, and guide outsourcing production;',
                        'Complete good communication and high-level connection with the animator and the program of the animator, and integrate the UI effects and functions with the completion of animation, programs, and special effects;',
                        'Accumulate and share UI design experience.',
                        '<br/><b>Job requirements:</b>',
                        'Graduated from art-related majors, college degree or above, with a strong sense of design, color perception and art hand-painting skills, understand human-computer interaction design, and have experience in leading the team to carry out design work is preferred;',
                        'Proficient in conventional software required for UI design;',
                        'Have strong comprehensive analysis ability, innovation ability, accurate understanding of work needs and efficient completion;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                        'Creative, full of imagination and creativity, active thinking, passionate about the game business;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                    ]
                },{
                    name: '2D game scene designer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the scene art design in the game, the setting of the scene art style, and the optimization of the scene details;',
                        'Responsible for cooperating with other positions and procedures of art to improve the final performance of the game;',
                        'Responsible for the docking management CP, providing clear requirements to the corresponding CP and controlling the output quality.',
                        '<br/><b>Job requirements:</b>',
                        'College degree or above, more than 3 years of relevant work experience in game scene design, CP management experience is preferred;',
                        'Familiar with the scene design of various art styles, good at European and American cartoon art style is preferred;',
                        'It needs the ability to control the overall atmosphere of the scene, strong modeling ability, superior color perception, solid art foundation, strong style adaptability and learning ability, can continuously improve the expressive power of game art, and be familiar with mainstream game art styles. Enthusiasm in the game industry;',
                        'Familiar with the game UI development process and have a good sense of teamwork, can work well with others;',
                        'Have good communication skills and teamwork spirit, proactively solve problems, and have a strong sense of responsibility.',
                    ]
                },{
                    name: 'Senior 2D game scene designer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the scene art design in the game, the setting of the scene art style, and the optimization of the scene details;',
                        'Responsible for cooperating with other positions and procedures of art to improve the final performance of the game;',
                        'Responsible for the docking management CP, providing clear requirements to the corresponding CP and controlling the output quality.',
                        '<br/><b>Job requirements:</b>',
                        'College degree or above, more than 3 years of relevant work experience in game scene design, CP management experience is preferred;',
                        'Familiar with the scene design of various art styles, good at European and American cartoon art style is preferred;',
                        'It needs the ability to control the overall atmosphere of the scene, strong modeling ability, superior color perception, solid art foundation, strong style adaptability and learning ability, can continuously improve the expressive power of game art, and be familiar with mainstream game art styles. Enthusiasm in the game industry;',
                        'Have good communication skills and teamwork spirit, proactively solve problems, and have a strong sense of responsibility.',
                    ]
                }],
                // 技术
                [{
                    name: 'U3D game development engineer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the design and development of the functional modules of the Unity mobile game project',
                        'Responsible for restoring and developing the planning function',
                        'Responsible for the development of game editing tools',
                        'Responsible for UGUI production and component development',
                        'Shader effect writing',
                        '<br/><b>Job requirements:</b>',
                        'Bachelor degree or above, love games, and plan for in-depth development in game design in the future',
                        'Familiar with C#, lua and other programming languages and object-oriented programming, with a good code style',
                        'Understand and master the writing of Unity ShaderLab',
                        'Familiar with Unity3D development environment',
                        'Familiar with the underlying mechanisms such as UGUI and UI batching',
                        'Pursue product quality and experience, be able to analyze and judge problems from the perspective of players, propose multiple implementation methods for a game function, and weigh different implementations',
                        'Good communication with others, strong sense of responsibility and teamwork spirit',
                    ]
                },{
                    name: 'Senior Unity Game Engineer',
                    msg: [
                        '<b>Job responsibilities:</b>',
                        'Responsible for the design and development of the functional modules of the Unity mobile game project',
                        'Responsible for restoring and developing the planning function',
                        'Responsible for the development of game editing tools',
                        'Responsible for UGUI production and component development',
                        'Shader effect writing',
                        'Core single game development',
                        'Core framework optimization iteration',
                        '<br/><b>Job requirements:</b>',
                        'Bachelor degree or above, love games, and plan for in-depth development in game design in the future',
                        'Familiar with C#, lua and other programming languages and object-oriented programming, with a good code style',
                        'Understand and master the writing of Unity ShaderLab',
                        'Familiar with Unity3D development environment',
                        'Familiar with the underlying mechanisms such as UGUI and UI batching',
                        'Pursue product quality and experience, be able to analyze and judge problems from the perspective of players, propose multiple implementation methods for a game function, and weigh different implementations',
                        'Good communication with others, strong sense of responsibility and teamwork spirit',
                    ]
                }],
                
            ],

            lstShow: [],
            curBtnIdx: 0,
        }
    },
    methods: {
        ShowInfo(idx) {
            this.curBtnIdx = idx
            this.lstShow = this.$store.state.isCN ? this.DETAIL_CN[idx] : this.DETAIL_EN[idx]
        },
        onBtnClick(idx) {
            console.log(idx)
            this.ShowInfo(idx)
        },
        OnApplyClick(idx) {
            var type = this.curBtnIdx;
            console.log("type: " + type + " idx: " + idx)
            window.location.href = "mailto:hr@ogopogo.games"
        },
    },
    watch: {
        '$store.state.isCN': function(newVal) {
            if (newVal) {
                this.lstShow = this.DETAIL_CN[this.curBtnIdx]
            } else {
                this.lstShow = this.DETAIL_EN[this.curBtnIdx]
            }
        }
    },
    created() {
        this.curBtnIdx = 0
        this.ShowInfo(0)
    }
}
</script>

<style scoped>
.page-job {
    /* display: flex;
    flex-direction: column;

    padding-top: 8%;
    padding-left: 4%;
    width: 94%;
    height: 100%; */
}

.group-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 4%;
}

.btn {
    background-color: #CCCCCC;
    border: 1px solid #CCCCCC;
    color: black;
    padding: 2px 39px;
    margin-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 21vh;
    cursor: pointer;
}

.btn.active {
    background-color: #013FED;
    border: 1px solid #013FED;
    color: white;
}

.btn:hover {
    background-color: #013FED;
    border: 1px solid #013FED;
    color: white;
}

.group-apply-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* padding-inline-start: 40px; */

    width: 100%;
    height: 25px;
}

.apply-btn {
    background-color: #FFFFFF;
    border: 1px solid #013FED;
    color: #013FED;
    padding: 0 38px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 21vh;
    cursor: pointer;
    
    height: 25px;
}

.apply-btn:hover {
    background-color: #013FED;
    border: 1px solid #013FED;
    color: white;
}

.group-detail {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;

    width: 96%;
    height: 70%;
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: hidden;

}

.group-detail:hover {
    overflow-y: visible;
}

.detail {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    /* width: 380px; */
    height: auto;
    min-height: 38%;
    margin-bottom: 20px;
}

.detail .title {
    font-weight: bold;
    text-align: left;
    /* padding-inline-start: 40px; */

    height: 30px;
}

.detail .job-detail {
    display: inline-flex;
    width: fit-content;
}

.detail > p {
    color: black;
    font-weight: bold;
    text-align: left;

    width: 100%;
    padding-inline-start: 40px;
}

.detail .job-detail ul {
    list-style-type: none;
    margin-top: 0;
}

.detail .job-detail ul > li {
    text-align: left;
    line-height: 23px;
    color: #404040;
}

ul > li > p {
    margin: 0;
}


</style>