<template>
  <div class="nav-menu-btn" :class='showClass'>
    <img class="close-btn btn" src="../assets/close_btn.png" alt="close" @click="onCloseClick"/>
      <div class="btns">
        <div class="lang-cn" v-if="$store.state.isCN">
          <div class="nav-btn btn" :class="{ active: navIndex == (index + 1) }" v-for="(item, index) in BTNS_CN" :key="index"
          @click="onNavClick(index + 1)" @mouseover="onItemHover()">
            {{item}}
          </div>
        </div>
        <div class="lang-en-retro" v-else>
          <div class="nav-btn btn" :class="{ active: navIndex == (index + 1) }" v-for="(item, index) in BTNS_EN" :key="index"
          @click="onNavClick(index + 1)" @mouseover="onItemHover()">
            {{item}}
          </div>
        </div>
      </div>

      <!-- <div class="share">
        <img v-for="(item, i) in lstShare" :key="i" :src="item" />
      </div> -->
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: [],
  data() {
    return {
      'showClass': '',
      navIndex: 0,

      BTNS_CN: [
        '主页','关于我们','加入我们','联系我们'
      ],

      BTNS_EN: [
        'HOME', 'ABOUT', 'JOBS', 'CONTACT'
      ],

      lstShare: [
        require('../assets/share_1.png'),
        require('../assets/share_2.png'),
        require('../assets/share_3.png'),
        require('../assets/share_4.png'),
      ]
    }
  },
  methods: {
    onCloseClick() {
      console.log('[NavMenu.onCloseClick]')
      this.showClass = ''
    },
    showMenu() {
      this.showClass = 'show'

      var cnt = this.$store.state.curPage
      this.navIndex = cnt
      console.log('[showMenu]', cnt, this.navIndex)
    },
    onNavClick(idx) {
      console.log('[NavMenu.onNavClick]', idx)
      this.navIndex = idx
      this.$emit('navigateTo', idx)
      this.onCloseClick()
    },
    onItemHover() {
      this.navIndex = 0
    }
  }
}
</script>

<style scoped>
.nav-menu-btn {
    position: absolute;
    right: -500px;
    top: 0;
    /* width: 500px; */
    height: 100%;
    font-family: mplus;
    background-color: white;

    transition-property: right;
    transition-duration: 0.4s;

    z-index: 10;
}

.nav-menu-btn.show {
    right: 0px;
}

.close-btn {
  position: absolute;
    /* width: 26px;
    height: 25px;
    right: 50px; */
    top: 27px;
}

.nav-menu-btn .btns {
    display: flex;
    flex: 1 1 54px;
    flex-direction: column;

    width: 100%;
    height: auto;
    /* margin-top: 25%; */
    align-items: center;
}

.nav-menu-btn .nav-btn {
    width: 250px;
    height: 45px;
    background-color: white;
    color: #013fed;
    font-size: 1.1rem;
    line-height: 42px;
    text-align: center;
    border-radius: 25px;
    /* margin-bottom: 54px; */
}

.nav-menu-btn .nav-btn.active {
    background-color: #013fed;
    color: white;
}

.nav-menu-btn .nav-btn:hover {
    background-color: #cccccc;
    color: white;
}

.nav-menu-btn .share {
    position: absolute;
    width: 100%;
    bottom: 8%;
}

.nav-menu-btn .share img {
    margin: 0 auto;
    padding: 0 4% 0 4%;
}
</style>
